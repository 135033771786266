import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Computer Programming`}</h1>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/working-with-files.pdf"
      }}>{`Working with files`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Sub-Programs.pdf"
      }}>{`Sub Programs`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/String_Functions.pdf"
      }}>{`String_Functions`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/REAL-NUMBERS.pdf"
      }}>{`REAL NUMBERS`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/PRINT-USING-COMMAND.pdf"
      }}>{`PRINT USING COMMAND`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Introduction_to_Computer.pdf"
      }}>{`Introduction_to_Computer`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/LEC-2-COLOR-AND-SCREEN.pdf"
      }}>{`COLOR AND SCREEN`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Multiplication-of-matrices.docx"
      }}>{`Multiplication of matrices`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/OCTAL-and-HEXADECIMAL.pdf"
      }}>{`OCTAL and HEXADECIMAL`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/OPERATORS_AND_EXPRERSSIONS.pdf"
      }}>{`OPERATORS_AND_EXPRERSSIONS`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Binary_Number_System.pdf"
      }}>{`Binary_Number_System`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/ASCII-UNICODE.pdf"
      }}>{`ASCII & UNICODE`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/arrays.pdf"
      }}>{`Arrays`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Introduction-to-QB.pdf"
      }}>{`Introduction to QB`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Graphics-in-Visual-Basic.pdf"
      }}>{`Graphics in Visual Basic`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/graphics-in-QB.pdf"
      }}>{`Graphics in QB`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/Conversion-Dec2Bin.pdf"
      }}>{`Conversion Dec2Bin`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/02/controlling-execution-with-control-structures.pdf"
      }}>{`Controlling execution with control structures`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      